.contactgegevens {
  width:100%;
  position:absolute;
  height:120px;
  z-index:100;
  background:rgba(255,255,255,0.4);
  .table-container {
    height:120px;
    width:100%;
    .table-inner {
      width:100%;
      .logo {
        max-width:230px;
        padding:0 15px;
        position:relative;
        float:left;
      }
      .contact {
        float:right;
        margin-top:3px;
        ul {
          margin:0;
          padding:0;
          list-style: none;
          float:left;
          li {
            float:left;
            a {
              color:$greyDark;
              width:100%;
              text-align:center;
              display:block;
              margin:0 auto;
              padding:12px 40px 6px 40px;
              border-right:1px solid $greyCard;
              transition:ease all 0.25s;
              -webkit-transition:ease all 0.25s;
              -moz-transition:ease all 0.25s;
              span {
                text-transform:uppercase;
                &.icon {
                  border:3px solid $greyDark;
                  border-radius:50%;
                  display:block;
                  width:40px;
                  height:40px;
                  text-align:center;
                  padding:4px 0;
                  margin:0px auto 6px auto;
                  i.fa-comments {
                    font-size:22px;
                    padding-top:2px;
                  }
                  i.fa-envelope {
                    font-size:20px;
                    padding-top:1px;
                  }
                  i.fa-phone {
                    font-size:23px;
                    padding-top:3px;
                  }
                }
              }
              &:hover,
              &:focus {
                text-decoration: none;
                color:$green;
                span {
                  &.icon {
                    border-color:$green;
                  }
                }
              }
            }
            &:last-child {
              a {
                border-right:0;
                padding-right:0;
              }
            }
          }
        }
      }
    }
  }
}