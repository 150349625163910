#topnav {
  width:100%;
  height:30px;
  background:$greyLight;
  min-height:30px;
  position:fixed;
  top:0;
  left:0;
  right:0;
  max-width:1330px;
  margin:0 auto;
  z-index:1100;

  #navbar-top {
    padding-right:0;
    .navbar-nav {
      float:right;
      li {
        a {
          padding:0 8px;
          margin:8px 0 8px 0;
          font-size:14px;
          line-height:14px;
          vertical-align:middle;
          color:$grey;
          text-transform:lowercase;
          font-family: $font-standard;
          border-right:1px solid $grey;
          &:hover,
          &:focus {
            background:transparent;
            color:$green;
          }
        }
        &:last-child {
          a {
            border-right:0;
            margin-right:0;
            padding-right:0;
          }
        }
      }
    }
  }
}