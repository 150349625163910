.btn-primary {
  background:$green;
  color: $greyDark;
  text-decoration: none;
  display:block;
  border:0;
  border-bottom:3px solid $greenDark;
  text-transform: uppercase;
  text-shadow:none;
  padding:15px 30px 13px 30px;
  font-weight:bold;
  transition: all 0.15s ease-in-out;
  &:hover,
  &:focus {
    background:$greyDark;
    border-color:black;
    color:white;
  }
}