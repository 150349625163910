$greyLight:               #f9f9f9;
$greyDark:                #312e2f;
$grey:                    #acacac;
$greyCard:                #ebebeb;
$green:                   #bfc33b;
$greenDark:               #939621;
$greenCard:               #939621;

$font-standard:           'Helvetica', 'Arial', sans-serif;
$font-sans:               'Open Sans', sans-serif;


