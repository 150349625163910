@media only screen and (max-width: 991px) {
  #topnav {
    .container {
      width:100%;
    }
  }
  #search-form {
    display:none;
  }
  header {
    .image-container {
      width:100%;
      &:before {
        border-top: 0px solid white;
        border-right: 0px solid transparent;
      }
    }
    .title {
      display:none;
    }
    /*
    .title {
      width:45%;
      .v-container {
        .v-inner {
          padding-left:40px;
          span {
            font-size:20px;
            line-height:45px;
          }
          span + span {
            font-size:36px;
            line-height:36px;
          }
        }
      }
    }*/
  }
  .navbar-default {
    .container-fluid {
      width:100%;
      padding:0;
      #navbar {
        .navbar-left {
          li {
            a {
              padding:26px 10px 24px 10px;
              font-size:14px;
            }
          }
        }
      }
    }
    .navbar-form {
      width:190px;
    }
  }

  .woningen {
    .woning {
      .txt {
        .fa-ul {
          li {
            span {
              padding-left:0;
            }
            i {
              top:0.35em;
              font-size:16px;
            }
          }
        }
      }
      &:after {
        content:"\f061";
      }
    }
  }

  .contactgegevens {
    .container {
      width:100%;
    }
    .table-container {
      .table-inner {
        .logo {
          max-width:210px;
        }
        .contact {
          margin-top:20px;
          ul {
            li {
              a {
                padding:4px 5px 2px 5px;
                border-right:0;
                span + span {
                  display:none;
                }
              }
            }
          }
        }
      }
    }
  }

  footer {
    .column {
      a.btn-logo {
        img {
          margin:0 auto 40px auto;
          display:block;
          max-width:400px;
        }
      }
    }
  }
}