footer {
  background:$green;
  color:$greyDark;
  padding:45px 0;
  float:left;
  width:100%;
  .row {
    .col-md-4 {
      &:first-child {
        .column {
          padding:0;
        }
      }
    }
  }
  .column {
    float:left;
    width:100%;
    padding:0 0 0 60px;
  }
  h4 {
    font-weight:bold;
    font-size:20px;
    margin:0 0 4px 0;
    padding:0;
  }
  a {
    color:$greyDark;
    &:hover,
    &:focus {
      color:white;
      text-decoration: none;
    }
  }
  ul {
    margin:0;
    padding:0;
    list-style:none;
  }
}