html, body {
  padding:0;
  margin:0;
  font-family: $font-standard;
  font-size:16px;
  line-height:1.9em;
  color:$greyDark;
  position:relative;
  background:$greyLight;
}
html {
  height:100%;
}
body {
  min-height:100%;
  height:100%;
  overflow-x:hidden;
  padding-top:30px;
  font-weight:300;
}
a {
  color:$green;
  &:hover,
  &:focus {
    outline:0;
    color:$greenDark;
  }
}
.table-container {
  display: table;
  .table-inner {
    display: table-cell;
    vertical-align:middle;
  }
}

p {
  margin:0 0 2em 0;
}
.wrapper {
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;
  background: white;
  z-index: 999;
  display:block;
  overflow:auto;
  box-shadow: 0px -1px 2px #ccc;
}
.banner {
  width:100%;
  float:left;
  text-transform: uppercase;
  padding:30px 60px;
  font-size:22px;
  line-height:36px;
  font-family: $font-standard;
  h1 {
    margin:0;
    padding:0;
    font-size:22px;
    line-height:36px;
    font-family: $font-standard;
  }
  &.green {
    background:$green;
    color:$greyDark;
  }
}
.overview {
  background:white;
  padding:60px 0 30px 0;
  width:100%;
  float:left;
}
.page-title {
  background:$greyDark;
  float:left;
  width:100%;
  padding:18px 0 18px 0;
  h1 {
    font-size:44px;
    color:$green;
    margin:0;
    padding:0;
    font-weight:700;
  }
  h2,
  a.back {
    font-size:18px;
    font-weight:300;
    margin:3px 0 0 0;
    padding:0;
    color:white;
    text-transform: uppercase;
    font-family: $font-standard;
  }
  a.back {
    margin:4px 0 0 0;
    float:left;
    i {
      margin-right:4px;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      color:$grey;
    }
  }
}
.fotos,
.main-content {
  float:left;
  width:100%;
  padding:30px 0;
  h2, h3 {
    margin:0 0 5px 0;
    padding:0;
    font-weight:700;
  }
  h2 {
    color:$green;
  }
  p, ul {
    margin:0 0 27px 0;
  }
  ul {
    padding:0 0 0 18px;
  }
  .form-block,
  .text-block {
    padding-right:30px;
    float:left;
    width:100%;
  }
  .text-block {
    img {
      max-width:100%;
      display:block;
      height:auto !important;
    }
  }

  .list-group {
    float:left;
    width:100%;
    background:$greyLight;
    border-radius:5px;
    border:1px solid $greyCard;
    h3 {
      padding:18px 15px 16px 15px;
      margin:0;
      color:$greenDark;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background:$green;
      font-weight:400;
      font-size:20px;
      text-transform: uppercase;
    }
    ul {
      margin:0;
      padding:0;
      list-style:none;
      li {
        border-top:1px solid white;
        float:left;
        width:100%;
        position:relative;
        a {
          padding:8px 15px;
          float:left;
          width:100%;
          font-weight:300;
          color:$greyDark;
          overflow:hidden;
          position: relative;
          i {
            color:$grey;
            margin-right:4px;
            &.fa-phone {
              font-size:17px;
            }
          }
          &:after {
            content: "\f061";
            font-family: FontAwesome;
            position: absolute;
            right: 5px;
            top: 6px;
            color: $greenDark;
            font-size: 76px;
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }
          &:hover,
          &:focus {
            text-decoration: none;
            background:$green;
            color:white;
            i {
              color:white;
            }
            &:after {
              right: 15px;
              opacity: 1;
              text-decoration: none;
            }
          }
        }
        &.adres {
          div {
            padding:8px 15px 8px 44px;
            float:left;
            width:100%;
            font-weight:300;
            color:$greyDark;
            position: relative;
            i {
              color:$grey;
              margin-right:4px;
            }
            &:before {
              content:"\f015";
              font-family: FontAwesome;
              position:absolute;
              left:15px;
              top:6px;
              color:$grey;
            }
          }

        }
      }
      &.openingstijden {
        div {
          padding:8px 15px;
          float:left;
          width:100%;
          font-weight:300;
          color:$greyDark;
          position: relative;
          span {
            min-width:40px;
            display:block;
            float:left;
            font-weight:700;
          }
          span + span {
            font-weight:300;
          }
        }
      }
    }
  }
  .date {
    color:$green;
    margin:0 0 30px 0;
  }
}

ul.pdf {
  margin:0;
  padding:0;
  list-style:none;
  li {
    position:relative;
    display:block;
    border-bottom:1px solid $greyCard;
    padding:8px 0 5px 25px;
    &:before {
      content:"\f1c1";
      position:absolute;
      left:0;
      top:6px;
      color:$greyDark;
      font-family: FontAwesome;
    }
    &:last-child {
      border-bottom:0;
    }
  }
}

.table > tbody > tr > td, .table > tbody > tr > th,
.table > tfoot > tr > td, .table > tfoot > tr > th,
.table > thead > tr > td, .table > thead > tr > th {
  border-color:$greyCard;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
  background:$greyLight;
}

#googleMaps {
  width:100%;
  height:450px;
}

.alert-info {
  i {
    margin-right:4px;
  }
}

ul.list-results {
  margin:0 !important;
  padding:0;
  list-style:none;
  width:100%;
  float:left;
  display:block;
  li {
    float:left;
    position:relative;
    width:100%;
    margin:0 0 15px 0;
    a {
      float:left;
      width:100%;
      background:$greyCard;
      position:relative;
      display:block;
      padding:15px 20px;
      border-radius:5px;
      border-bottom:3px solid $grey;
      transition:ease all 0.25s;
      p {
        margin:0;
      }
      .readmore {
        border-top:1px solid $grey;
        color:$grey;
        position:relative;
        padding-top:3px;
        margin-top:9px;
        &:after {
          content:"\f178";
          font-family: FontAwesome;
          position:absolute;
          right:0;
          top:5px;
          color:$grey;
        }
      }
      &:hover,
      &:focus {
        h2 {
          color:white;
        }
        .readmore {
          border-color:white;
          color:white;
          &:after {
            color:white;
          }
        }
        text-decoration: none;
        background:$green;
        border-color:$greenDark;
      }
    }
  }
}