ul.medewerkers {
  margin:0;
  padding:0 30px 0 0 !important;
  list-style:none;
  float:left;
  width:100%;
  li {
    float:left;
    width:33.333333%;
    padding:0 10px 10px 0;
  }
}
.employee {
  width:100%;
  float:left;
  background:$greyCard;
  border-radius:5px;
  .image {
    padding:5px;
  }
  .desc {
    padding:5px;
    min-height:125px;
    .name {
      font-weight:700;
      text-transform:uppercase;
      line-height:1.5em;
      color:$greyDark;
    }
    .functie {
      font-weight:300;
      font-size:14px;
      line-height:1.5em;
    }
  }
}