#faq-accordion {
  margin:30px 0 30px 0;
  .panel-default {
    border:0;
    box-shadow:none;
    background:transparent;
    .panel-heading {
      background:transparent;
      border:0;
      border-bottom:1px solid $green;
      box-shadow:none;
      padding-left:3px;
      h4.panel-title {
        border:0;
        background:transparent;
        font-weight:400;
        font-family: $font-standard;
        font-size:16px;
        text-decoration:none;
        a {
          text-decoration:none;
          i {
            float:right;
          }
        }
      }
    }
    .panel-body {
      padding:15px 3px;
      border-top:0;
      p {
        margin:0;
      }
    }
  }
}