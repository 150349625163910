.woningen {
  background:$greyLight;
  width:100%;
  float:left;
  padding:45px 0 15px 0;
  .woning {
    .image {
      width:40%;
      img {
        max-width:100%;
      }
    }
    .txt {
      width:60%;
      padding:15px 20px 46px 20px;
      h2 {
        width:100%;
        float:left;
        span {
          font-size:20px;
          font-weight:700;
          line-height:25px;
          text-transform: inherit;
        }
        span + span {
          font-weight:300;
          font-size:16px;
        }
      }
      .fa-ul {
        width:100%;
        font-size:14px;
        color:$grey;
        float:left;
        margin:4px 0 0 0;
        padding:0;
        li {
          padding-left: 20px;
          padding-top:3px;
          width:100%;
          float:left;
          border-bottom:1px solid $greyLight;
          span {
            float:left;
            padding-left:4px;
            color:$grey;
          }
          span + span {
            float:right;
            padding-left:0;
            font-weight:400;
          }
          i {
            left:-15px;
            top:7px;
            font-size:18px;
          }
          &:last-child {
            border:0;
          }
        }

      }
    }
    &.verhuurd {
      .image {
        span {
          position:absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color:white;
          z-index:100;
          text-transform:uppercase;
          font-size:20px;
          transition: all 0.2s ease;
        }
        &:after {
          left:0;
          background: rgba(230,153,0,0.8);
        }
      }
      &:hover,
      &:focus {
        border-color:#c38200 !important;
        .image {
          span {
            top:25%;
          }
        }
        &:after {
          color:#c38200;
        }
      }
    }
  }
}

.woning-detail {
  float:left;
  padding:45px 0;
  width:100%;
  h2 {
    font-weight:700;
    color:$green;
  }
  .kenmerken {
    float:left;
    width:100%;
    padding-right:30px;
    .table {
      tr {
        td {
          border-color:$greyCard;
          vertical-align: middle;
          .fa-check {
            color:$green;
          }
          .fa-close {
            color:rgba(230,153,0,1.0);
          }
          span {
            display:block;

            width:30px;
            height:30px;
            color:white;
            text-align:center;
            padding-top:5px;
          }
          span.energie {
            text-transform: uppercase;
          }
          span.label-a {
            background:#427C0C;
          }
          span.label-b {
            background:#66C507;
          }
          span.label-c {
            background:#C7EE07;
          }
          span.label-d {
            background:#F4E904;
          }
          span.label-e {
            background:#F0C708;
          }
          span.label-f {
            background:#EA8809;
          }
          span.label-g {
            background:#E81E26;
          }
          &:first-child {
            font-weight:700;
            max-width:220px;
            width:220px;
          }
        }
      }
    }
  }
  .tabs-container {
    margin-top:30px;
    width:100%;
    float:left;
    .nav-tabs {
      border-bottom-color:$greyCard;
      li {
        a {
          &:hover {
            background:$greyLight;
            color:$green;
          }
        }
        &.active {
          a {
            border-color:$greyCard;
            border-bottom-color:transparent;
          }
        }
      }
    }
    .tab-pane {
      padding:30px 0;
    }
  }
}
#omschrijving {
  .btn-primary {
    display:inline-block;
  }
}
