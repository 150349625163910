@media only screen and (min-width: 768px) {
  .navbar-default {
    width: 100%;
    height: 70px;
    background: $green;
    position: absolute;
    top: 120px;
    margin: 0;
    z-index: 1000;
    border: 0;
    border-radius: 0;
    .container-fluid {
      padding-left:0;
    }
    button {
      display:none;
    }

    #navbar {
      display: block;
      border: 0;
      margin: 0;
      padding: 0;
      background: $green;
      .navbar-left {
        border: 0;
        margin: 0;
        padding: 0;
        li {
          a {
            background: $green;
            border-right: 1px solid $greenDark;
            color: $greyDark;
            text-transform: uppercase;
            font-family: $font-standard;
            font-weight: bold;
            font-size: 16px;
            height: 70px;
            display: block;
            text-align: center;
            padding: 26px 20px 24px 20px;
            transition: ease all 0.25s;
            -webkit-transition: ease all 0.25s;
            -moz-transition: ease all 0.25s;
            &:hover,
            &:focus {
              background: $greyDark;
              color: white;
            }
          }
          &.active {
            a {
              background: $greyDark;
              color: white;
            }
          }
          &:first-child {
            a {
              border-left:0px solid $greenDark;
            }
          }
          &:last-child {
            a {
              border-right: 0;
            }
          }
          &.dropdown {
            .dropdown-menu {
              padding:0;
              border:0;
              box-shadow:none;
              li {
                a {
                  min-width:200px;
                  background:$greyLight;
                  border:0;
                  border-bottom:1px solid $greyCard;
                  color:$greyDark;
                  padding:10px 20px 8px 20px;
                  text-align:left;
                  font-weight:400;
                  text-transform:inherit;
                  height:40px;
                  &:hover,
                  &:focus {
                    background:$green;
                  }
                }
                &:last-child {
                  a {
                    border:0;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                  }
                }
              }
            }
            &:hover {
              .dropdown-menu {
                display:block;
              }
            }
          }
        }
      }
    }
    &.fixed {
      position: fixed;
      top: 30px;
      width: 100%;
      max-width:1300px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .navbar-form {
    width: 320px;
    height: 70px;
    position: relative;
    margin: 0 -15px 0 0;
    padding: 0;
    transition: ease all 0.2s;
    .form-group {
      width: 100%;
      height: 100%;
      .form-control {
        width: 100%;
        height: 100%;
        border: 0;
        border-top:1px solid $green;
        border-bottom:1px solid $green;
        box-shadow:0 0 0 transparent;
        border-radius: 0;
        background: white;
        color: $green;
        font-size: 18px;
        padding-left: 48px;
        &:before {
          content: "\f002";
          position: absolute;
          font-size: 14px;
          left: 0;
          top: 1px;
          color: $green;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .form-control::-webkit-input-placeholder {
        color: $green;
        font-family: $font-standard;
      }
      .form-control::-moz-placeholder {
        color: $green;
        font-family: $font-standard;
      }
      .form-control:-ms-input-placeholder {
        color: $green;
        font-family: $font-standard;
      }
      .form-control:-moz-placeholder {
        color: $green;
        font-family: $font-standard;
      }
    }
    .search {
      position: relative;
      &:before {
        content: "\f002";
        font-family: FontAwesome;
        position: absolute;
        left: 15px;
        top: 18px;
        color: $green;
        font-size: 22px;
      }
    }
  }
}