@media only screen and (max-width: 767px) {
  body {
    padding-top: 0px;
  }
  #topnav {
    #navbar-top {
      .navbar-nav {
        margin: 7px 0;
        li {
          float: left;
          a {
            padding: 0 4px;
            margin: 0 0px;
            display: block;
            float: left;
          }
        }
      }
    }
  }

  .navbar-default {
    top: 30px;
    height: 50px;
    position: fixed;
    margin: 0;
    width: 100%;
    background: $green;
    z-index: 1101;
    .container-fluid {
      padding: 0 15px;

      #navbar {
        background: white;
        .navbar-nav {
          margin: 0 -15px;
          li {
            border-bottom:1px solid $greyLight;
            a {
              padding: 16px 12px 14px 12px;
              font-family: $font-standard;
              font-size:20px;
              text-align:center;
            }
            &.dropdown {
              .dropdown-menu {
                padding:0;
                li {
                  a {
                    background:$greyLight;
                    padding:8px 20px 6px 20px;
                    font-size:16px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbar-header {
      button {
        background: transparent;
        border: 0;
        float: right;
        width: 50px;
        height: 35px;
        position: relative;
        margin: 9px 10px 5px 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        span {
          display: block;
          position: absolute;
          height: 4px;
          width: 100%;
          background: white;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out;
        }
        span:nth-child(1) {
          top: 0;
        }

        span:nth-child(2) {
          top: 12px;
        }

        span:nth-child(3) {
          top: 24px;
        }
      }
      .menu-toggle.open span:nth-child(1) {
        top: 12px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      .menu-toggle.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      .menu-toggle.open span:nth-child(3) {
        top: 12px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
    .navbar-form {
      width:100%;
      margin:0;
      border-radius:0;
      border:0;
    }
  }

  .overview {
    padding: 30px 0 0 0;
  }

  .banner {
    padding:15px 0;
    h1 {
      font-size:14px;
    }
  }

  .contactgegevens {
    margin-top:80px;
    .table-container {
      .table-inner {
        .logo {
          max-width:190px;
        }
      }
    }
  }
  #home-slider {
    margin-top:200px;
  }
  #slider {
    .owl-item {
      .slide {
        .item-text {
          padding-top:420px;
          padding-left:0;
          text-align:center;
          z-index:2000;
          height:auto;
          width:100%;
          display:block;

          .title {
            width:100%;
            font-size:24px;
            line-height:30px;
            height:auto;
            padding:8px 0;
            text-align:center;
            background:rgba(255,255,255,0.75);
            span {
              line-height:30px;
              text-align:center;
            }
          }
        }
      }
    }
    .owl-controls {
      .owl-dots {
        bottom:15px;
        width:115px;
      }
    }
  }

  header {
    margin-top:200px;
    height:200px;
    .bg {
      height:250px;
      min-height:250px;
    }
    .image-container {
      height:200px;
      .image {
        height:200px;
      }
    }
    .title {
      font-size:25px;
      line-height:36px;
      margin-top:15px;
      margin-left:15px;
      width:100%;
      height:auto;
      span {
        line-height:36px;
      }
      span + span {
        font-size:36px;
      }
    }
  }

  ul.medewerkers {
    padding:0 !important;
    li {
      width:100%;
      padding:10px 0;
    }
  }

  .page-title {
    h1 {
      font-size:36px;
    }
  }
  .table-responsive {
    border:0;
  }

  .form {
    .col {
      padding-right:15px;
      padding-left:15px;
    }
    .col + .col {
      padding-right:15px;
      padding-left:15px;
    }
  }

  footer {
    .column {
      a.btn-logo {
        img {
          max-width:100%;
          margin:0 auto;
          padding:0 30px;
        }
      }
      h4 {
        margin-top:30px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .page-title {
    h1 {
      font-size:25px;
    }
    h2, a.back {
      font-size:16px;
      margin:5px 0 0 0;
    }
  }

  .woningen {
    .woning {
      .image {
        width:100%;
        border-bottom-left-radius: 0;
      }
      .txt {
        width:100%;
        padding-bottom:50px;
      }
    }
  }
}