@media only screen and (max-width: 1199px) {
  header {
    .bg {
      background-size:100% !important;
      background-attachment: scroll !important;
      background-position: 0 -50px !important;
    }
  }
  header {
    .title {
      .v-container {
        .v-inner {
          padding-left:55px;
          span {
            font-size:20px;
            line-height:45px;
          }
          span + span {
            font-size:36px;
            line-height:36px;
          }
        }
      }
    }
  }
  #home-slider {
    height:320px;
  }
  #slider {
    height:320px;
    min-height:320px;
    z-index:1;

    .owl-item {
      height:320px !important;
      .slide {
        height:320px !important;

        .item-text {
          padding-top:70px;
          .title {
            font-size:32px;
            line-height:36px;
            width:450px;
            height:155px;
            span {
              line-height:44px;
            }
          }

        }
      }
    }
  }

  .navbar-default {
    #navbar {
      .navbar-left {
        li {
          a {
            padding:26px 18px 24px 18px;
            font-size:15px;
          }
        }
      }
    }
  }
  .navbar-form {
    width:200px;
    .form-group {
      .form-control {
        font-size:15px;
      }
    }
    .search {
      &:before {
        font-size:20px;
      }
    }
  }

  .banner {
    padding:20px 30px;
    h1 {
      font-size:18px;
      line-height:25px;
    }
  }

  .card {
    .txt {
      padding:10px;
      h2 span {
        font-size:26px;
        line-height:28px;
      }
      h2 span + span {
        font-size:24px;
      }
      span.desc {
        font-size:15px;
      }
    }
    &:after {
      font-size:20px;
      width:20px;
      right:6px;
      bottom:3px;
    }
  }
  .woningen {
    .woning {
      .txt {
        padding:12px 15px;
        .fa-ul {
          li {
            span {
              padding-left:0;
            }
            i {
              top:0.35em;
              font-size:16px;
            }
          }
        }
      }
      &:after {
        content:"";
      }
    }
  }
}