.form-group {
  .form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius:0;
    padding:10px 12px;
    border-color:$greyCard;
    text-shadow:none;
    box-shadow:none;
    transition: all 0.3s ease-in-out;
    &:focus {
      border-color:$grey;
    }
  }
  input.form-control {
    height:48px;
  }
  textarea {
    height:180px;
    resize:none;
  }
}
.alert {
  border-radius:0;
}
.alert-danger {
  background:#FB979C;
  color:#A11E25;
  border-color:#C13A41;
}
.alert-success {
  background:$green;
  color:$greenDark;
  border-color:$greenDark;
}

.form {
  .col {
    padding-right:8px;
    padding-left:15px;
  }
  .col + .col {
    padding-right:15px;
    padding-left:7px;
  }
}
h3 + .form {
  margin-top:15px;
}

// PAGINATION

.pagination {
  li {
    a {
      color:$green;
      &:hover,
      &:focus {
        background:$green;
        color:white;
        border-color:$greenDark;
      }
    }
    &.active {
      span {
        background:$green;
        border-color:$greenDark;
        &:hover,
        &:focus {
          background:$green;
          border-color:$greenDark;
        }
      }
    }
  }
}