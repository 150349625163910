header {
  margin-top:190px;
  width:100%;
  height:300px;
  float:left;
  overflow:hidden;
  .bg {
    float:left;
    width:100%;
    height:700px;
    min-height:700px;
    position:relative;
    .overlay {
      width:100%;
      height:100%;
      background:rgba(0,0,0,0.1);
      position:absolute;
      z-index:0;
      top:0;
    }
  }

  .image-container {
    width:62%;
    height:300px;
    position:relative;
    float:right;
    &:before {
      position:absolute;
      content:"";
      width: 0;
      height: 0;
      border-top: 300px solid white;
      border-right: 100px solid transparent;
    }
    .image {
      width:100%;
      height:300px;
      display:block;
    }
  }
  .title {
    width:38%;
    float:left;
    height:300px;
    .v-container {
      display: table;
      height:100%;
      .v-inner {
        height:100%;
        display: table-cell;
        vertical-align: middle;
        padding-left:80px;
        span {
          font-weight:300;
          line-height: 50px;
          text-align: left;
          display: block;
          text-transform: uppercase;
          color:$green;
          text-shadow:0 0 1px $grey;
          font-size:28px;
          font-family: $font-standard;
        }
        span + span {
          font-weight: 700;
          font-size: 48px;
          line-height:48px;
        }
      }
    }
  }
  /*
  .title {
    display:block;
    text-align: left;
    padding:0;
    margin-top:62px;
    margin-left:62px;
    font-size:36px;
    width:600px;
    height:155px;
    color:white;
    text-shadow:0 0 1px $grey;
    font-family: $font-standard;
    font-weight:300;
    z-index:1001 !important;
  }
  */
}