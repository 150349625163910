.card {
  width:100%;
  float:left;
  border-radius:5px;
  border-bottom:3px solid $greyCard;
  background:$greyLight;
  transition: all 0.2s ease;
  position:relative;
  margin:0 0 30px 0;
  .image {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
    width: 35%;
    height: 100%;
    display: block;
    float:left;
    overflow:hidden;
    z-index:9;
    img {
      transition: all 0.2s ease;
    }
    &:before {
      content: "\f002";
      font-family: FontAwesome;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 10px;
      width: 40px;
      height: 42px;
      line-height: 40px;
      text-align: center;
      opacity: 0;
      transition: all 0.2s ease;
      z-index: 101;
    }
    &:after {
      content:"";
      background: rgba(191,195,58,0.8);
      width:100%;
      height:100%;
      position:absolute;
      top:0;
      left:-100%;
      transition: all 0.2s ease;
    }
  }
  .txt {
    float:left;
    width:65%;
    display:block;
    position:relative;
    padding:15px 20px;
    color:$greyDark;
    h2 {
      margin:0 0 5px 0;
      padding:0;
      color:$green;
      display:block;
      width:100%;
      float:left;
      font-family: $font-sans;
      span {
        font-weight:400;
        display:block;
        text-align:left;
        line-height:36px;
        text-transform: inherit;
        font-size:32px;
      }
      span + span {
        font-weight:700;
        text-transform: uppercase;
        font-size:28px;
      }
    }
    span.desc {
      font-size:16px;
      line-height:22px;
      float:left;
      width:100%;
    }
  }
  &:after {
    content:"\f061";
    font-family: FontAwesome;
    position:absolute;
    right:0px;
    bottom:10px;
    width:40px;
    height:30px;
    font-size:30px;
    color:$greyCard;
  }
  &:hover,
  &:focus {
    border-color:$greenCard;
    .image {

      &:before {
        top: 50%;
        left:50%;
        font-size: 40px;
        opacity: 1;
      }

      &:after {
        top:0;
        left:0;
      }
    }
    &:after {
      color:$green;
    }
  }
}

.albums {
  .card {
    .txt {
      h2 {
        span {
          line-height:30px;
        }
      }
    }
  }
}

.woningen {
  .card {
    background:white;
  }
}

.albums {
  .card {
    .txt {
      h2 {
        span {
          font-size:24px;
        }
      }
    }
  }
}