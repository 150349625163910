.foto-thumb {
  width:100%;
  float:left;
  border-radius:5px;
  border-bottom:3px solid $greyCard;
  background:white;
  transition: all 0.2s ease;
  position:relative;
  margin:0 0 30px 0;
  .image {
    border-radius:5px;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    float:left;
    overflow:hidden;
    z-index:9;
    img {
      transition: all 0.2s ease;
    }
    &:before {
      content: "\f002";
      font-family: FontAwesome;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 10px;
      width: 40px;
      height: 42px;
      line-height: 40px;
      text-align: center;
      opacity: 0;
      transition: all 0.2s ease;
      z-index: 101;
    }
    &:after {
      content:"";
      background: rgba(191,195,58,0.8);
      width:100%;
      height:100%;
      position:absolute;
      top:0;
      left:-100%;
      transition: all 0.2s ease;
    }
  }
  &:after {
    content:"\f061";
    font-family: FontAwesome;
    position:absolute;
    right:0px;
    bottom:10px;
    width:40px;
    height:30px;
    font-size:30px;
    color:$greyCard;
  }
  &:hover,
  &:focus {
    border-color:$greenCard;
    .image {
      &:before {
        top: 50%;
        left:50%;
        font-size: 40px;
        opacity: 1;
      }
      &:after {
        top:0;
        left:0;
      }
    }
    &:after {
      color:$green;
    }
  }
}